@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    font-family: "Inter", sans-serif;
}

body {
    background-color: #0e1119;
}

::-webkit-scrollbar {
    width: 10px;
    border-radius: 5px;
}

::-webkit-scrollbar-track {
    background: #151b2c;
}

::-webkit-scrollbar-thumb {
    background: #7cf3a9;
    border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}
